import React, {ChangeEventHandler, useEffect, useState} from "react";
import "./Shared.css";
import {Button, Form, Table} from "react-bootstrap";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {RoleDto} from "../../../../dto";
import {RoleTypeDto} from "../../../../dto/RoleTypeDto";
import InputForm from "../../../../components/InputForm/InputForm";
import {roleFields} from "../data/roleFields";
import useAuthToken from "../../../../helpers/authHelper";

export default function RolesTab() {
    const role: RoleDto = {
        appRoleId: 0,
        appRoleType: "",
        isActive: false,
        name: "",
        priority: undefined,
        rights: []
    };
    const crudApi = process.env.REACT_APP_APP_API;
    const navigate = useNavigate();
    const [roles, setRoles] = useState<RoleDto[]>([]);
    const [filterText, setFilterText] = useState("");
    const [rerender, setRerender] = useState(false);
    
    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const setAllRoles = () => {
        axios.get(`${process.env.REACT_APP_APP_API}/Roles`, config)
            .then(result => {
                setRoles(result.data);
            });
    }

    const setAllRoleTypes = () => {
        axios.get(`${crudApi}/RoleTypes`, config)
            .then(result => {
                roleFields[2].options = result.data.map((roleType: RoleTypeDto) => roleType.name);
            })
    }

    const setRolesByName = (roleName: string) => {
        axios.get(`${process.env.REACT_APP_APP_API}/Roles/${roleName}`, config)
            .then((result) => {
                setRoles(result.data);
            });
    }

    const deleteRole = (roleId: number) => {
        axios.delete(`${process.env.REACT_APP_APP_API}/Roles/${roleId}`, config)
            .then(() => {
                setRoles(roles.filter((role: RoleDto) => role.appRoleId !== roleId));
            });
    }

    const onFilterChange:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        setFilterText(e.target.value);
    }

    const onFilterSubmit = () => {
        setRolesByName(filterText);
    }

    const onClearSubmit = () => {
        setAllRoles();
        setFilterText("");
    }

    const onSubmitEvent = () => {
        setRerender(!rerender);
    }

    useEffect(() => {
        setAllRoles();
        setAllRoleTypes();
    }, [rerender]);

    return (
        <div className="tab-width">
            <InputForm title="Manage App Roles"
                       data={roleFields}
                       submitText="Add"
                       apiEndpoint={`${crudApi}/Roles`}
                       endpointType="post"
                       baseApiBody={role}
                       onSubmitEvent={onSubmitEvent}
                       routePostSubmit="/peregrine-crud/user-admin/roles" />
            <Form>
                <Form.Group className="mb-3" controlId="rolesFilterForm">
                    <Form.Label className="field-label">Filter Roles By Name:</Form.Label>
                    <Form.Control type="text" onChange={onFilterChange}/>
                </Form.Group>
                <Button onClick={onFilterSubmit} aria-label="roleSearch" className="filter-button">Search</Button>
                <Button onClick={onClearSubmit} aria-label="roleClear" className="filter-button">Clear</Button>
            </Form>

            <Table responsive hover>
                <thead>
                <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Priority</th>
                    <th>App Role Type</th>
                    <th>Is Active</th>
                </tr>
                </thead>
                <tbody>
                {roles.map((role: RoleDto, index: number) => {
                    return <tr key={index}>
                        <td aria-label="role-cells" className="no-wrap">
                            <Button aria-label="editRole"
                                    variant="link"
                                    onClick={() => navigate(`/peregrine-crud/user-admin/role/edit/${role.appRoleId}`)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button aria-label="deleteRole"
                                    variant="link"
                                    onClick={() => deleteRole(role.appRoleId)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </td>
                        <td aria-label="role-cells">{role.appRoleId}</td>
                        <td aria-label="role-cells">{role.name}</td>
                        <td aria-label="role-cells">{role.priority}</td>
                        <td aria-label="role-cells">{role.appRoleType}</td>
                        <td aria-label="role-cells">{role.isActive ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    );
}