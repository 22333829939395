import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SignOutButton = () => {

    const {instance} = useMsal();

    const handleSignOut = () => {
        instance.logoutRedirect();
    }

    return (

      <>
      <a href='#' style={{textDecoration: 'none'}} color="inherit" onClick={handleSignOut} >Sign Out</a>
      </>
      //<Link to="first" style={{ textDecoration: 'none' }}></Link>

    )
};