export interface FormField {
    type: FormFieldType;
    label: string;
    apiField: string;
    options: string[];
    required: boolean;
    controlId: string;
}

export enum FormFieldType {
    Text = "text",
    Checkbox = "checkbox",
    Dropdown = "dropdown",
    TextArea = "textarea"
}