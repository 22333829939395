import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from "react";
import "./InputForm.css";
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import {FormField} from "../../pages/crud/user-admin/types/FormField";
import {useNavigate} from "react-router-dom";
import useAuthToken from '../../helpers/authHelper';

export default function InputForm(props: {
    title: string;
    data: FormField[];
    submitText: string;
    apiEndpoint: string;
    endpointType: string;
    baseApiBody: any;
    onSubmitEvent: () => void;
    routePostSubmit: string}) {
    let title = props.title,
        data = props.data,
        submitText = props.submitText,
        apiEndpoint = props.apiEndpoint,
        endpointType = props.endpointType,
        baseApiBody = props.baseApiBody,
        onSubmitEvent = props.onSubmitEvent,
        routePostSubmit = props.routePostSubmit;
    const [apiBody, setApiBody] = useState<any>(baseApiBody);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const onTextChange:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = (e) => {
        const name = e.target.name;
        const value = e.target.value === "" ? null : e.target.value;
        setApiBody({...apiBody, [name]: value});
    };
    const onCheckboxChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setApiBody({...apiBody, [name]: value});
    };
    const callEndpoint = (endpoint: string, type: string, values: any) => {
        if (type === "update") {
            axios.put(endpoint, values, config).then(() => onSubmitEvent());
        } else {
            axios.post(apiEndpoint, values, config).then(() => onSubmitEvent());
        }
    };
    const submitForm:FormEventHandler<HTMLFormElement> = (e) => {
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            callEndpoint(apiEndpoint, endpointType, apiBody);
            navigate(routePostSubmit);
        }

        setValidated(true);
    };
    const renderFormField = (formField: FormField) => {
        let type = formField.type,
            label = formField.label,
            apiField = formField.apiField,
            options = formField.options,
            required = formField.required;
        switch(type) {
            case "text":
                return <div>
                    <Form.Label className="field-label">{label}</Form.Label>
                    <Form.Control name={apiField}
                                  type={type}
                                  value={apiBody[apiField]}
                                  onChange={onTextChange}
                                  required={required}/>
                </div>;
            case "checkbox":
                return <Form.Check name={apiField}
                                   type={type}
                                   label={label}
                                   checked={apiBody[apiField]}
                                   onChange={onCheckboxChange}
                                   required={required}/>;
            case "dropdown":
                return <div>
                    <Form.Label className="field-label">{label}</Form.Label>
                    <Form.Select name={apiField}
                                 value={apiBody[apiField]}
                                 onChange={onTextChange}
                                 required={required}>
                        <option value=""></option>
                        {options.map((option: string, index: number) => {
                            return <option key={index} value={option}>{option}</option>
                        })}
                    </Form.Select>
                </div>
            case "textarea":
                return <div>
                    <Form.Label className="field-label">{label}</Form.Label>
                    <Form.Control name={apiField}
                                  as="textarea"
                                  rows={4}
                                  value={apiBody[apiField]}
                                  onChange={onTextChange}
                                  required={required}/>
                </div>
        }
    };

    useEffect(() => {
        setApiBody(baseApiBody);
    }, [baseApiBody]);

    return (
        <div>
            <h4>{title}</h4>
            <hr/>
            <Form onSubmit={submitForm} aria-label="input-form" noValidate validated={validated}>
                {data.map((formField: FormField, index: number) => {
                    return (
                        <Form.Group className="mb-3" key={index} controlId={formField.controlId}>
                            {renderFormField(formField)}
                        </Form.Group>
                    );
                })}
                <Button type="submit">{submitText}</Button>
            </Form>
            <hr/>
        </div>
    )
}