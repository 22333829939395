import { Button } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useMsalAuthentication, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import axios from 'axios';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../authz-config';

const Home = () => {
    const { instance } = useMsal();
    // use the scame scope as in login request
    const apiScopes = loginRequest.scopes;

    // set MSAL authentication token
    const { result, error: msalError } = useMsalAuthentication(InteractionType.Redirect, {
        scopes: apiScopes,
        account: instance.getActiveAccount() ?? undefined
    });

    let [apiResult, setApiResult] = useState('')
 
    if (msalError) {
        console.log(JSON.stringify(`MSAL Error: ${msalError}`));
    }

    // API endpoint to call
    const testApi = process.env.REACT_APP_APP_API ?? 'this should be API url';

    const callApi = () => {

        const accessToken = result?.accessToken;

        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-type': "application/json",
                'Accept': "*/*",
            }
        };

        console.log(`Executing Api call to ${testApi}  Token: ${accessToken}`)

        // call propetected API endpoint
        axios.get(`${testApi}/test`, config)
            .then((result) => {
                console.log(`Respone = ${JSON.stringify(result)}`);
                setApiResult('API returned :' + result.data);
            }).catch(error => {
                console.log(`Error = ${JSON.stringify(error)}`)
            });
    };


    const handleSignout = () => {
        console.log("Loging out...");
        instance.logoutRedirect();
    }

    useEffect(() => {
        if (result) {
            console.log(`Home Page: Is authenticated - ${JSON.stringify(result)}`);
        }

        if (msalError) {
            console.log(`Home Page: Is authenticated - ${JSON.stringify(msalError)}`);
        }
    }, []);

    return (
        <div>
            {/* <SiteHeader siteType={SiteTypes.CRUD} headerText="CRUD HOME" /> */}
            <AuthenticatedTemplate>
                <br />&nbsp;&nbsp;&nbsp;&nbsp;You are signed in.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={callApi}>Make API Call to the Web API</Button>
                <br /> <br />
                <label> { apiResult } </label>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                &nbsp;&nbsp;Please sign in.
            </UnauthenticatedTemplate>
        </div>
    )
};

export default Home;