import {FormField, FormFieldType} from "../types/FormField";

export const userFields: FormField[] = [
    {
        type: FormFieldType.Text,
        label: "First Name",
        apiField: "firstName",
        options: [],
        required: true,
        controlId: "formFirstName"
    },
    {
        type: FormFieldType.Text,
        label: "Last Name",
        apiField: "lastName",
        options: [],
        required: true,
        controlId: "formLastName"
    },
    {
        type: FormFieldType.Text,
        label: "Email",
        apiField: "emailAddress",
        options: [],
        required: false,
        controlId: "formEmail"
    },
    {
        type: FormFieldType.Text,
        label: "ScoutID",
        apiField: "scoutId",
        options: [],
        required: false,
        controlId: "formScoutId"
    },
    {
        type: FormFieldType.Text,
        label: "Priority",
        apiField: "priority",
        options: [],
        required: false,
        controlId: "formUserPriority"
    },
    {
        type: FormFieldType.Text,
        label: "Pro Report Priority",
        apiField: "proReportPriority",
        options: [],
        required: false,
        controlId: "formProReportPriority"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Hide Reporting?",
        apiField: "hideReporting",
        options: [],
        required: false,
        controlId: "formHideReporting"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Include CT Grades?",
        apiField: "includeCTGrades",
        options: [],
        required: false,
        controlId: "formIncludeCtGrades"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Include Team Grades?",
        apiField: "includeTeamGrades",
        options: [],
        required: false,
        controlId: "formIncludeTeamGrades"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Active?",
        apiField: "isActive",
        options: [],
        required: false,
        controlId: "formUserIsActive"
    },
];