import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from "react";
import "./EditRight.css";
import "./SharedEdit.css";
import {Button, Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RightDto, RoleDto, UserDto} from "../../../../dto";
import axios from "axios";
import useAuthToken from '../../../../helpers/authHelper'

export default function EditRight() {
    let { rightId } = useParams();
    const [right, setRight] = useState<RightDto>({
        appRightId: Number(rightId),
        name: "",
        description: "",
        isActive: false
    });
    const [validated, setValidated] = useState(false);
    const [rolesWithRight, setRolesWithRight] = useState<RoleDto[]>([]);
    const [usersWithRight, setUsersWithRight] = useState<UserDto[]>([]);
    const crudApi = process.env.REACT_APP_APP_API;
    const navigate = useNavigate();

    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const getAndSetRight = () => {
        axios.get(`${crudApi}/Rights/${rightId}`, config)
            .then((result) => {
                setRight(result.data);
            });
    };
    const getAndSetRolesWithRight = () => {
        axios.get(`${crudApi}/Rights/Roles?rightId=${right.appRightId}`, config)
            .then((result) => {
                setRolesWithRight(result.data);
            });
    };
    const getAndSetUsersWithRight = () => {
        axios.get(`${crudApi}/Rights/Users?rightId=${right.appRightId}`, config)
            .then((result) => {
                setUsersWithRight(result.data);
            });
    };
    const updateRight:FormEventHandler<HTMLFormElement> = (e) => {
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            axios.put(`${crudApi}/Rights`, right).then(r => console.log(r));
        }

        setValidated(true);
    };

    const onTextChange:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        const name = e.target.name;
        const value = e.target.value === "" ? null : e.target.value;
        setRight({...right, [name]: value});
    };
    const onCheckboxChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setRight({...right, [name]: value});
    };

    useEffect(() => {
        getAndSetRight();
        getAndSetRolesWithRight();
        getAndSetUsersWithRight();
    }, []);

    return (
        <div className="edit-flex">
            <Button variant="link" onClick={() => navigate("/peregrine-crud/user-admin/rights")} className="rights-back-link">
                <FontAwesomeIcon icon={faArrowLeft} />return to rights
            </Button>
            <h4>Edit Right</h4>
            <hr/>
            <Form onSubmit={updateRight} noValidate validated={validated}>
                <Form.Group className="mb-3" controlId="formRightName">
                    <Form.Label className="field-label">Name</Form.Label>
                    <Form.Control name="name"
                                  type="text"
                                  value={right.name}
                                  onChange={onTextChange}
                                  required={true} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRightDescription">
                    <Form.Label className="field-label">Priority</Form.Label>
                    <Form.Control name="description"
                                  type="text"
                                  value={right.description}
                                  onChange={onTextChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRightIsActive">
                    <Form.Check name="isActive"
                                type="checkbox"
                                label="Active?"
                                checked={right.isActive}
                                onChange={onCheckboxChange}/>
                </Form.Group>
                <Button type="submit">Update Right</Button>
            </Form>

            <div>
                <h5>Roles With Right</h5>
                <ul className="no-bullets-list">
                    {rolesWithRight.map((role) => {
                        return <li aria-label="right-roles">
                            <a href={`/peregrine-crud/user-admin/role/edit/${role.appRoleId}`}>{role.name}</a>
                        </li>
                    })}
                </ul>
            </div>
            <div>
                <h5>Users With Right</h5>
                <ul className="no-bullets-list">
                    {usersWithRight.map((user) => {
                        return <li aria-label="right-users">
                            <a href={`/peregrine-crud/user-admin/user/edit/${user.appUserId}`}>{user.firstName} {user.lastName}</a>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}