import React, {useEffect, useState} from 'react';
import "./SiteHeader.css";
import logo from "../../assets/logo.png";
import { Navigation } from "common-ambse";
import {NavMenuItem} from "common-ambse/dist/esm/components/Navigation/Navigation.types";
import axios from "axios";
import SiteHeaderProps, {SiteTypes} from "./SiteHeaderProps";
import useAuthToken from '../../helpers/authHelper';

function SiteHeader(props: SiteHeaderProps) {
    let { siteType, headerText } = props;

    const webApi = process.env.REACT_APP_APP_API;
    const peregrine1Url = process.env.REACT_APP_PEREGRINE_1_URL
    const [navMenu, setNavMenu] = useState<NavMenuItem[]>([]);

    const homeNavMenuItem: NavMenuItem[] = [{
        item: "My Home",
        url: `${peregrine1Url}/peregrine`,
        childItems: []
    }];

    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };
    
    const setNavMenuItems = () => {
    
        axios.get(`${webApi}/Navigation/Menu`, config)
        .then((result) => {
            result.data.forEach((navItem: NavMenuItem) => {
                navItem.url = `${peregrine1Url}${navItem.url}`
            })
            setNavMenu(homeNavMenuItem.concat(result.data.slice(1)));
        });
    }

    const headerColor = () => {
        switch (siteType) {
            case SiteTypes.CRUD:
                return "crud-header"
            default:
                return "base-header"
        }
    }

    useEffect(() => {
        setNavMenuItems();
    }, [accessToken]);

    return(
        <div className={`header ${headerColor()}`}>
            <img src={logo} className="falcons-logo"  alt="falcons-logo"/>
            <div>
                <div className="main-header-size">ATLANTA FALCONS</div>
                <div className="sub-header-size">{headerText}</div>
            </div>
            <Navigation menuItems={navMenu} />
        </div>
    );
}

export default SiteHeader;