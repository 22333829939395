export default interface SiteHeaderProps {
    siteType: SiteTypes,
    headerText: string
}

export enum SiteTypes {
    PEREGRINE,
    CRUD,
    PRO,
    COLLEGE,
    ATHLETIC_PERFORMANCE,
    CONTRACTS,
    SEARCH
}