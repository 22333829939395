import React, {ChangeEventHandler, useEffect, useState} from "react";
import "./Shared.css";
import {Button, Form, Table} from "react-bootstrap";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {UserDto} from "../../../../dto";
import InputForm from "../../../../components/InputForm/InputForm";
import {userFields} from "../data/userFields";
import useAuthToken from "../../../../helpers/authHelper";

export default function UsersTab() {
    const user: UserDto = {
        appUserId: 0,
        emailAddress: "",
        firstName: "",
        hideReporting: false,
        includeCTGrades: false,
        includeTeamGrades: false,
        isActive: false,
        lastName: "",
        priority: undefined,
        proReportPriority: undefined,
        rights: [],
        roles: [],
        scoutId: ""
    };
    const crudApi = process.env.REACT_APP_APP_API;
    const navigate = useNavigate();
    const [users, setUsers] = useState<UserDto[]>([]);
    const [rerender, setRerender] = useState<boolean>(false);
    const [filterText, setFilterText] = useState("");
    
    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const setAllUsers = () => {
        axios.get(`${crudApi}/Users`, config)
            .then(result => {
                setUsers(result.data);
            });
    }

    const setUsersByLastName = (lastName: string) => {
        axios.get(`${crudApi}/Users/${lastName}`, config)
            .then((result) => {
                setUsers(result.data);
            });
    }

    const onFilterChange:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        setFilterText(e.target.value);
    }

    const onFilterSubmit = () => {
        setUsersByLastName(filterText);
    }

    const onClearSubmit = () => {
        setAllUsers();
        setFilterText("");
    }

    const onSubmitEvent = () => {
        setRerender(!rerender);
    }

    useEffect(() => {
        setAllUsers();
    }, [rerender]);

    return (
        <div className="tab-width">
            <InputForm title="Manage App Users"
                       data={userFields}
                       apiEndpoint={`${crudApi}/Users`}
                       baseApiBody={user}
                       endpointType="post"
                       submitText="Add"
                       onSubmitEvent={onSubmitEvent}
                       routePostSubmit="/peregrine-crud/user-admin/users"/>
            <Form>
                <Form.Group className="mb-3" controlId="usersFilterForm">
                    <Form.Label className="field-label">Filter Users By Last Name:</Form.Label>
                    <Form.Control value={filterText} type="text" onChange={onFilterChange}/>
                </Form.Group>
                <Button onClick={onFilterSubmit} aria-label="userSearch" className="filter-button">Search</Button>
                <Button onClick={onClearSubmit} aria-label="userClear" className="filter-button">Clear</Button>
            </Form>

            <Table responsive hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>ScoutID</th>
                        <th>Email</th>
                        <th>Priority</th>
                        <th>Pro Report Priority</th>
                        <th>Hide Reporting?</th>
                        <th>Active?</th>
                        <th>Include CT Grades?</th>
                        <th>Include Team Grade?</th>
                    </tr>
                </thead>
                <tbody>
                {users.map((user: UserDto, index: number) => {
                    return <tr key={index}>
                        <td aria-label="user-cells"><Button aria-label="editUser"
                                    variant="link"
                                    onClick={() => navigate(`/peregrine-crud/user-admin/user/edit/${user.appUserId}`)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button></td>
                        <td aria-label="user-cells">{user.appUserId}</td>
                        <td aria-label="user-cells">{user.firstName} {user.lastName}</td>
                        <td aria-label="user-cells">{user.scoutId}</td>
                        <td aria-label="user-cells">{user.emailAddress}</td>
                        <td aria-label="user-cells">{user.priority}</td>
                        <td aria-label="user-cells">{user.proReportPriority}</td>
                        <td aria-label="user-cells">{user.hideReporting ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
                        <td aria-label="user-cells">{user.isActive ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
                        <td aria-label="user-cells">{user.includeCTGrades ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
                        <td aria-label="user-cells">{user.includeTeamGrades ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    );
}
