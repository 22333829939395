import { UnauthenticatedTemplate, AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Navbar, Nav, NavItem, NavLink } from "react-bootstrap";
import { WelcomeName } from "./Welcome";
import { Button } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

const NavigationBar = () => {
    const IsAuthenticated = useIsAuthenticated();

    return (
        <Nav activeKey="/home">
            <Nav.Item>
                <Nav.Link href="/home">Home</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link>
                    {IsAuthenticated ? <WelcomeName /> : null}
                </Nav.Link>
            </Nav.Item>

            <AuthenticatedTemplate>
                <Nav.Item >
                    <Nav.Link href="/peregrine">Peregrine</Nav.Link>
                </Nav.Item>
                <Nav.Item >
                    <Nav.Link href="/peregrine-crud">User manager</Nav.Link>
                </Nav.Item>
                <Nav.Item >
                    <Nav.Link href="/profile">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <NavLink>
                        <SignOutButton></SignOutButton>
                    </NavLink>
                </Nav.Item>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Nav.Item>
                    <NavLink>
                        <SignInButton></SignInButton>
                    </NavLink>
                </Nav.Item>
            </UnauthenticatedTemplate>
        </Nav>
    )
}

export default NavigationBar;