import React, {useState} from 'react';
import "./UserAdmin.css";
import UsersTab from "./tabs/UsersTab";
import RolesTab from "./tabs/RolesTab";
import {Tab, Tabs} from "react-bootstrap";
import RightsTab from "./tabs/RightsTab";
import {useParams} from "react-router-dom";

export default function UserAdmin() {
    let { tab } = useParams();
    const [activeTab, setActiveTab] = useState(tab);
    return (
        <div className="user-admin-flex">
            <h2 className="title">User Admin</h2>

            <Tabs activeKey={activeTab} onSelect={(tab: any) => setActiveTab(tab)}>
                <Tab eventKey="users" title="Users">
                    <UsersTab />
                </Tab>
                <Tab eventKey="roles" title="Roles">
                    <RolesTab />
                </Tab>
                <Tab eventKey="rights" title="Rights">
                    <RightsTab />
                </Tab>
            </Tabs>
        </div>
    );
}
