import SiteHeader from "../../components/SiteHeader/SiteHeader";
import {Routes} from "react-router-dom";
import React from "react";
import {SiteTypes} from "../../components/SiteHeader/SiteHeaderProps";

export default function BaseRouting() {
    return (
        <div>
            <SiteHeader siteType={SiteTypes.PEREGRINE} headerText="PEREGRINE"/>
            <Routes>
            </Routes>
        </div>
    )
}