import React, {ChangeEventHandler, FormEventHandler, MouseEventHandler, useEffect, useState} from "react";
import "./EditUser.css";
import "./SharedEdit.css";
import {Button, Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import { UserDto, RoleDto, RightDto, UserRoleDto, UserRightDto } from "../../../../dto";
import {userFields} from "../data/userFields";
import InputForm from "../../../../components/InputForm/InputForm";
import useAuthToken from '../../../../helpers/authHelper'

export default function EditUser() {
    let { userId } = useParams();
    const [user, setUser] = useState<UserDto>({
        appUserId: Number(userId),
        firstName: "",
        lastName: "",
        emailAddress: "",
        scoutId: "",
        priority: undefined,
        proReportPriority: undefined,
        hideReporting: false,
        includeCTGrades: false,
        includeTeamGrades: false,
        isActive: false,
        roles: [],
        rights: []
    });
    const [assignedRoles, setAssignedRoles] = useState<RoleDto[]>([]);
    const [unassignedRoles, setUnassignedRoles] = useState<RoleDto[]>([]);
    const [assignedRoleRights, setAssignedRoleRights] = useState<RightDto[]>([]);
    const [assignedRights, setAssignedRights] = useState<RightDto[]>([]);
    const [unassignedRights, setUnassignedRights] = useState<RightDto[]>([]);
    const [userRoleToAdd, setUserRoleToAdd] = useState<UserRoleDto>();
    const [userRightToAdd, setUserRightToAdd] = useState<UserRightDto>();
    const [rerender, setRerender] = useState(false);

    const crudApi = process.env.REACT_APP_APP_API;

    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const onRoleSelectChange:ChangeEventHandler<HTMLSelectElement> = (e) => {
        const roleId = Number(e.currentTarget.value);
        const userRole: UserRoleDto = {
            userId: user.appUserId,
            roleId: roleId
        }
        setUserRoleToAdd(userRole);
    };
    const onRightSelectChange:ChangeEventHandler<HTMLSelectElement> = (e) => {
        const rightId = Number(e.currentTarget.value);
        const userRight: UserRightDto = {
            userId: user.appUserId,
            rightId: rightId
        };
        setUserRightToAdd(userRight);
    };
    const getAndSetUserRolesAndRights = () => {
        axios.get(`${crudApi}/Users/${userId}`, config)
            .then((result) => {
                setUser(result.data);
                setAssignedRoles(result.data.roles);
                setAssignedRoleRights(result.data.roles.flatMap((r: RoleDto) => r.rights));
                setAssignedRights(result.data.rights);
            });
    };
    const getAndSetUnassignedRoles = () => {
        axios.get(`${crudApi}/Users/UnassignedRoles/${userId}`, config)
            .then((result) => {
                setUnassignedRoles(result.data);
            });
    };
    const getAndSetRights = () => {
        axios.get(`${crudApi}/Users/UnassignedRights/${userId}`, config)
            .then((result) => {
                setUnassignedRights(result.data);
            });
    };
    const addRoleToUser:FormEventHandler<HTMLFormElement> = () => {
        axios.post(`${crudApi}/Users/Role`, userRoleToAdd, config)
            .then((result) => {
                console.log(result.data);
            })
    };
    const deleteRoleFromUser:MouseEventHandler<SVGSVGElement> = (e) => {
        const roleId = e.currentTarget.id;
        axios.delete(`${crudApi}/Users/Role?userId=${user.appUserId}&roleId=${roleId}`, config)
            .then(() => {
                setAssignedRoles(assignedRoles.filter((role) => role.appRoleId !== Number(roleId)));
            });
    };
    const addRightToUser:FormEventHandler<HTMLFormElement> = () => {
        axios.post(`${crudApi}/Users/Right`, userRightToAdd, config)
            .then((result) => {
                console.log(result.data);
            })
    };
    const deleteRightFromUser:MouseEventHandler<SVGSVGElement> = (e) => {
        const rightId = e.currentTarget.id;
        axios.delete(`${crudApi}/Users/Right?userId=${user.appUserId}&rightId=${rightId}`, config)
            .then(() => {
                setAssignedRights(assignedRights.filter((right) => right.appRightId !== Number(rightId)));
            });
    };

    const onSubmitEvent = () => {
        setRerender(!rerender);
    }

    useEffect(() => {
        getAndSetUserRolesAndRights();
        getAndSetUnassignedRoles();
        getAndSetRights();
    }, [rerender]);

    const navigate = useNavigate();

    return (
        <div className="edit-flex">
            <Button variant="link" onClick={() => navigate("/peregrine-crud/user-admin/users")} className="users-back-link">
                <FontAwesomeIcon icon={faArrowLeft} />return to users
            </Button>
            <InputForm title="Edit Users"
                       data={userFields}
                       apiEndpoint={`${crudApi}/Users`}
                       baseApiBody={user}
                       endpointType="update"
                       submitText="Update User"
                       onSubmitEvent={onSubmitEvent}
                       routePostSubmit={`/peregrine-crud/user-admin/user/edit/${userId}`}/>

            <div className="roles-rights-manager">
                <h3>Manage Roles/Rights</h3>
                <div>
                    <div>
                        <p>Roles</p>
                        <Form onSubmit={addRoleToUser}>
                            <Form.Select onChange={onRoleSelectChange}>
                                <option value={-1}>select a role...</option>
                                {unassignedRoles.map((role, index) => <option aria-label="unassigned-roles" value={role.appRoleId} key={index}>{role.name}</option>)}
                            </Form.Select>
                            <Button type="submit" className="add-button">Add</Button>
                        </Form>
                        <ul className="no-bullets-list">
                            {assignedRoles.map((role, index) => {
                                return <li aria-label="assigned-roles" key={index}>
                                    <Button variant="link" className="user-trash-icon">
                                        <FontAwesomeIcon icon={faTrash} id={`${role.appRoleId}`} onClick={deleteRoleFromUser}/>
                                    </Button>
                                    <a href={`/peregrine-crud/user-admin/role/edit/${role.appRoleId}`}>{role.name}</a>
                                </li>
                            })}
                        </ul>
                    </div>
                    <div>
                        <p>Rights</p>
                        <Form onSubmit={addRightToUser}>
                            <Form.Select aria-label="rights-select" onChange={onRightSelectChange}>
                                <option value={-1}>select a right...</option>
                                {unassignedRights.map((right, index) => <option aria-label="unassigned-rights" value={right.appRightId} key={index}>{right.name}</option>)}
                            </Form.Select>
                            <Button type="submit" className="add-button">Add</Button>
                        </Form>
                        <ul className="no-bullets-list">
                            {assignedRights.map((right, index) => {
                                return <li aria-label="assigned-rights" key={index}>
                                    <Button variant="link" className="user-trash-icon">
                                        <FontAwesomeIcon icon={faTrash} id={`${right.appRightId}`} onClick={deleteRightFromUser} />
                                    </Button>
                                    <a href={`/peregrine-crud/user-admin/right/edit/${right.appRightId}`}>{right.name}</a> [explicit]
                                </li>
                            })}
                            {assignedRoleRights.map((right, index) => {
                                return <li key={index}>
                                    <a href={`/peregrine-crud/user-admin/right/edit/${right.appRightId}`}>{right.name}</a> [implicit]
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}