import SiteHeader from "../../components/SiteHeader/SiteHeader";
import {Route, Routes} from "react-router-dom";
import UserAdmin from "./user-admin/UserAdmin";
import EditUser from "./user-admin/edit/EditUser";
import EditRole from "./user-admin/edit/EditRole";
import EditRight from "./user-admin/edit/EditRight";
import React from "react";
import {SiteTypes} from "../../components/SiteHeader/SiteHeaderProps";

export default function CrudRouting() {
    return (
        <div>
            <SiteHeader siteType={SiteTypes.CRUD} headerText="CRUD HOME" />
            <Routes>
                <Route path="user-admin/:tab" element={<UserAdmin />} />
                <Route path="user-admin/user/edit/:userId" element={<EditUser />} />
                <Route path="user-admin/role/edit/:roleId" element={<EditRole />} />
                <Route path="user-admin/right/edit/:rightId" element={<EditRight />} />
            </Routes>
        </div>
    )
}