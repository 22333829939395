import {FormField, FormFieldType} from "../types/FormField";

export const rightFields: FormField[] = [
    {
        type: FormFieldType.Text,
        label: "Name",
        apiField: "name",
        options: [],
        required: true,
        controlId: "formRightName"
    },
    {
        type: FormFieldType.Text,
        label: "Description",
        apiField: "description",
        options: [],
        required: false,
        controlId: "formRightDescription"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Active?",
        apiField: "isActive",
        options: [],
        required: false,
        controlId: "formRightIsActive"
    },
]