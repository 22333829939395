import { LogLevel } from "@azure/msal-browser";

// authentication configuratuon for MSAL
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_APP_CLIENT_ID ?? 'client id is missing',
        authority: process.env.REACT_APP_AUTHORITY ?? 'authorithy is missing',
        redirectUri: process.env.REACT_APP_REDIRECT_URL ?? 'redirect url is missing',
        postLogoutRedirectUri: process.env.REACT_APP_APP_POST_REDIRECT_URL ?? 'lohput redirect url is missing',
        navigateToLoginRequestUrl: false
    },
    cache: {
        // Configures cach location.
        // Session storage is more secure but local storage gives you sso between tabs
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                    default:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

// set desired scope
export const loginRequest = {
    scopes: [process.env.REACT_APP_API_SCOPE ?? 'api scope is missing']
}
