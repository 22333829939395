import React from 'react';
import './App.css';
import CrudRouting from "./pages/crud/CrudRouting";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BaseRouting from "./pages/base/BaseRouting";
import LoginNew from "./pages/base/Login/LoginNew";
import Logout from "./pages/base/Logout/Logout";
import axios from "axios";
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './index';
import Home from './pages/temp/Home';
import NavBar from './components/NavigationBar';

function App(instance: any) {
    axios.defaults.withCredentials = true;

    return (
        <MsalProvider instance={msalInstance}>
            <div>
                <NavBar></NavBar>
            </div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/peregrine/*" element={<BaseRouting />} />
                    <Route path="/peregrine-crud/*" element={<CrudRouting />} />
                    <Route path="/profile" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </MsalProvider>
    );
}

export default App;
