import {FormField, FormFieldType} from "../types/FormField";

export const roleFields: FormField[] = [
    {
        type: FormFieldType.Text,
        label: "Name",
        apiField: "name",
        options: [],
        required: true,
        controlId: "formRoleName"
    },
    {
        type: FormFieldType.Text,
        label: "Priority",
        apiField: "priority",
        options: [],
        required: false,
        controlId: "formRolePriority"
    },
    {
        type: FormFieldType.Dropdown,
        label: "App Role Type",
        apiField: "appRoleType",
        options: [],
        required: false,
        controlId: "formAppRoleType"
    },
    {
        type: FormFieldType.Checkbox,
        label: "Active?",
        apiField: "isActive",
        options: [],
        required: false,
        controlId: "formRoleIsActive"
    },
]