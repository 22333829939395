import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authz-config'

const useAuthToken = () => {
    const { instance } = useMsal();
    // use the scame scope as in login request
    const apiScopes = loginRequest.scopes;

    // set MSAL authentication token
    const { result, error: msalError } = useMsalAuthentication(InteractionType.Redirect, {
        scopes: apiScopes,
        account: instance.getActiveAccount() ?? undefined
    });

    if(msalError)
    {
        console.log(`MSAL error: ${msalError}`)
    }

    const accessToken = result?.accessToken;

    return accessToken;
};

export default useAuthToken;