import { useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authz-config';
import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export const SignInButton = () => {

    const {instance} = useMsal (); 

    const handleSignin = () => {
        console.log("Login Page: Loging in...");

        instance.loginRedirect(loginRequest)
        .then(res => {
            console.log(`Login Page: ${JSON.stringify(res)}`);
        })
        .catch(err => {
            console.log(`Login Page: ${JSON.stringify(err)}`);
        })
    }

    return (
        <>
        <a href='#' style={{textDecoration :'none'}}  color="inherit" onClick={handleSignin} >Sign in</a>
        </>
       //<Link to="first" style={{ textDecoration: 'none' }}></Link>
    );    
};

