import React, {ChangeEvent, ChangeEventHandler, FormEventHandler, useEffect, useState} from "react";
import "./EditRole.css";
import "./SharedEdit.css";
import {Button, Form, FormCheck, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {RightDto, RoleDto, RoleHierarchyDto, RoleHierarchyTypeDto, UserDto} from "../../../../dto";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import useAuthToken from '../../../../helpers/authHelper'

export default function EditRole() {
    let { roleId } = useParams();
    const test = ["Unknown",
        "Scouting",
        "Coaching",
        "Football Ops Staff",
        "Football Systems Staff",
        "Medical",
        "Executive"
    ];
    const [role, setRole] = useState<RoleDto>({
        appRoleId: Number(roleId),
        appRoleType: "",
        isActive: false,
        name: "",
        priority: undefined,
        rights: []
    });
    const [roleRights, setRoleRights] = useState<RightDto[]>([]);
    const [unassignedRights, setUnassignedRights] = useState<RightDto[]>([]);
    const [usersForRole, setUsersForRole] = useState<UserDto[]>([]);
    const [canSeeRoles, setCanSeeRoles] = useState<RoleDto[]>([]);
    const [roleHierarchies, setRoleHierarchies] = useState<RoleHierarchyDto[]>([]);
    const [roleHierarchyTypes, setRoleHierarchyTypes] = useState<RoleHierarchyTypeDto[]>([]);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const crudApi = process.env.REACT_APP_APP_API;

    const accessToken = useAuthToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-type': "application/json",
            'Accept': "",
        }
    };

    const getAndSetRole = () => {
        axios.get(`${crudApi}/Roles/${roleId}`, config)
            .then((result) => {
                setRole(result.data);
                setRoleRights(result.data.rights);
            });
        axios.get(`${crudApi}/Rights`, config)
            .then((result) => {
                setUnassignedRights(result.data.filter((right: RightDto) => !roleRights.includes(right)));
            })
    }
    const onTextChange:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = (e) => {
        const name = e.target.name;
        const value = e.target.value === "" ? null : e.target.value;
        setRole({...role, [name]: value});
    };
    const onCheckboxChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setRole({...role, [name]: value});
    };
    const updateRole:FormEventHandler<HTMLFormElement> = (e) => {
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            axios.put(`${crudApi}/Roles`, role, config).then(r => console.log(r));
        }

        setValidated(true);
    };
    const getRoles = () => {
        axios.get(`${crudApi}/Roles`, config)
            .then((result) => {
                setCanSeeRoles(result.data);
            });
    };
    const getUsersForRole = () => {
        axios.get(`${crudApi}/Roles/Users?roleId=${role.appRoleId}`, config)
            .then((result) => {
                setUsersForRole(result.data);
            });
    };
    const getRoleHierarchies = () => {
        axios.get(`${crudApi}/Roles/Hierarchies/${role.appRoleId}`, config)
            .then((result) => {
                console.log(result.data);
                setRoleHierarchies(result.data);
            });
    };
    const getRoleHierarchyTypes = () => {
        axios.get(`${crudApi}/RoleHierarchyTypes`, config)
            .then((result) => {
                setRoleHierarchyTypes(result.data);
            });
    };
    const onRoleHierarchyCheckboxChange = (e: ChangeEvent<HTMLInputElement>, roleHierarchyTypeId: number) => {
        const canSeeRoleId = Number(e.target.id);

        const newRoleHierarchy: RoleHierarchyDto = {
            appRoleHierarchyId: 0,
            appRoleHierarchyTypeId: roleHierarchyTypeId,
            appRoleId: role.appRoleId,
            canSeeAppRoleId: canSeeRoleId
        };
        if (e.target.checked) {
            axios.post(`${crudApi}/Roles/Hierarchies`, newRoleHierarchy, config)
                .then((result) => {
                    newRoleHierarchy.appRoleHierarchyId = result.data;
                    setRoleHierarchies([...roleHierarchies, newRoleHierarchy])
                });
        } else {
            axios.delete(`${crudApi}/Roles/Hierarchies?roleId=${role.appRoleId}&roleHierarchyTypeId=${roleHierarchyTypeId}&canSeeRoleId=${canSeeRoleId}`, config)
                .then((result) => {
                    newRoleHierarchy.appRoleHierarchyId = result.data;
                    setRoleHierarchies(roleHierarchies.filter((roleHierarchy) => roleHierarchy.appRoleHierarchyId !== newRoleHierarchy.appRoleHierarchyId));
                });
        }
    }

    useEffect(() => {
        getAndSetRole();
        getRoles();
        getUsersForRole();
        getRoleHierarchies();
        getRoleHierarchyTypes();
    }, []);

    return(
        <div className="edit-flex">
            <Button variant="link" onClick={() => navigate("/peregrine-crud/user-admin/roles")} className="roles-back-link">
                <FontAwesomeIcon icon={faArrowLeft} />return to roles
            </Button>
            <h4>Edit Role</h4>
            <hr/>
            <Form onSubmit={updateRole} noValidate validated={validated}>
                <Form.Group className="mb-3">
                    <Form.Label className="field-label">Name</Form.Label>
                    <Form.Control name="name"
                                  type="text"
                                  value={role.name}
                                  onChange={onTextChange}
                                  required={true} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="field-label">Priority</Form.Label>
                    <Form.Control name="priority"
                                  type="text"
                                  value={role.priority}
                                  onChange={onTextChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="field-label">App Role Type</Form.Label>
                    <Form.Select name="appRoleType"
                                 value={role.appRoleType}
                                 onChange={onTextChange}>
                        <option value=""></option>
                        {test.map((option: string, index: number) => {
                            return <option key={index} value={option}>{option}</option>
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check name="isActive"
                                type="checkbox"
                                label="Active?"
                                checked={role.isActive}
                                onChange={onCheckboxChange}/>
                </Form.Group>
                <Button type="submit">Update Role</Button>
            </Form>
            <div>
                <h5>Role Rights</h5>
                <hr/>
                <Form>
                    <Form.Select>
                        <option value={-1}>select a right...</option>
                        {unassignedRights.map((right) => <option value={right.appRightId}>{right.name}</option>)}
                    </Form.Select>
                    <Button type="submit" className="add-button">Add</Button>
                </Form>
                <ul className="no-bullets-list">
                    {roleRights.map((right) => {
                        return <li value={right.appRightId}>
                            <Button variant="link">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            <a href={`/peregrine-crud/user-admin/right/edit/${right.appRightId}`}>{right.name}</a>
                        </li>
                    })}
                </ul>
            </div>

            <div>
                <h5>Users Currently Assigned to Role</h5>
                <hr/>
                <ul className="no-bullets-list">
                    {usersForRole.map((user) => {
                        return <li>
                            <a href={`/peregrine-crud/user-admin/user/edit/${user.appUserId}`}>{user.firstName} {user.lastName}</a>
                        </li>
                    })}
                </ul>
            </div>

            <div>
                <h5>Role Hierarchy</h5>
                <hr/>
                <Table striped responsive hover>
                    <thead>
                        <tr>
                            <th></th>
                            {roleHierarchyTypes.map((roleHierarchyType) => <th>{roleHierarchyType.displayName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {canSeeRoles.map((role, index) => {
                        return <tr key={index}>
                            <th>{role.name}</th>
                            <th>
                                <FormCheck className="hierarchy-checkbox"
                                           id={`${role.appRoleId}`}
                                           checked={roleHierarchies.some((roleHierarchy: RoleHierarchyDto) => {
                                               return roleHierarchy.canSeeAppRoleId === role.appRoleId && roleHierarchy.appRoleHierarchyTypeId === 1;
                                           })}
                                           onChange={(e) => onRoleHierarchyCheckboxChange(e, 1)}/>
                            </th>
                            <th>
                                <FormCheck className="hierarchy-checkbox"
                                           id={`${role.appRoleId}`}
                                           checked={roleHierarchies.some((roleHierarchy: RoleHierarchyDto) => {
                                               return roleHierarchy.canSeeAppRoleId === role.appRoleId && roleHierarchy.appRoleHierarchyTypeId === 2;
                                           })}
                                           onChange={(e) => onRoleHierarchyCheckboxChange(e, 2)}/>
                            </th>
                            <th>
                                <FormCheck className="hierarchy-checkbox"
                                           id={`${role.appRoleId}`}
                                           checked={roleHierarchies.some((roleHierarchy: RoleHierarchyDto) => {
                                               return roleHierarchy.canSeeAppRoleId === role.appRoleId && roleHierarchy.appRoleHierarchyTypeId === 3;
                                           })}
                                           onChange={(e) => onRoleHierarchyCheckboxChange(e, 3)}/>
                            </th>
                            <th>
                                <FormCheck className="hierarchy-checkbox"
                                           id={`${role.appRoleId}`}
                                           checked={roleHierarchies.some((roleHierarchy: RoleHierarchyDto) => {
                                               return roleHierarchy.canSeeAppRoleId === role.appRoleId && roleHierarchy.appRoleHierarchyTypeId === 4;
                                           })}
                                           onChange={(e) => onRoleHierarchyCheckboxChange(e, 4)}/>
                            </th>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}